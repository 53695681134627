import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faLinkedinIn, faStrava } from '@fortawesome/free-brands-svg-icons'

import "./index.scss"
import "./contact.scss"

import ImgContact2 from "../images/contact/jaroslav-suchy.jpg"

import VideoBg from "../videos/ftrs-about-us.mp4"

const Contact = () => (

<div className="ftrs ftrs--contact">

    <Layout>
        <SEO title="Contact" description="We create awesome digital experience" />

		<div className="ftrs-background" data-sal="fade" data-sal-duration="2000">
			<video autoPlay="autoplay" muted loop className="ftrs-hero__video ftrs-hero__video--dark">
				<source media="(orientation: landscape)" src={VideoBg} type="video/mp4" />
			</video>
		</div>

        <Container className="ftrs-content ftrs-contact">

            <Row>

                <Col xs="12" lg="6" data-sal="fade" data-sal-delay="250" data-sal-duration="1000">
                    <div className="ftrs-contact__box">
                        <Row>
                            <Col xs="12" lg="4">
                                <img loading="lazy" src={ImgContact2} className="ftrs-contact__image" alt="" />
                            </Col>
                            <Col xs="12" lg="8">
                                <p className="ftrs-contact__name">
                                    Jaroslav Suchý
                                </p>
                                <p className="ftrs-contact__position text-basic">
                                    CTO
                                </p>
                                <p className="ftrs-contact__email">
                                    <a href="mailto:jarda@futurerockstars.cz">jarda@futurerockstars.cz</a><br />
                                </p>
                                <p className="ftrs-contact__phone">
                                    <a href="tel:+420721765529">+420 721 765 529</a>
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>

                <Col xs="12" lg="6" data-sal="fade" data-sal-delay="150" data-sal-duration="1000">
                    <h1 className="mt-5 ftrs-h2">Future Rockstars s.r.o.</h1>
                    <p className="mt-3 text-basic">
                        Baarova 1405/3a<br />
                        140 00&nbsp;&nbsp;Praha 4 - Michle<br />
                        Czech Republic
                    </p>
                    <a className="ftrs-button ftrs-button--small" href="https://goo.gl/maps/m3ki8BpBHwyhjiUn6" target="_blank" rel="noreferrer">our location</a>

                    <div className="mt-5">
                        <a aria-label="Linkedin" className="ftrs-socialicon ftrs-socialicon--linkedin" href="https://www.linkedin.com/company/future-rockstars" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                        <a aria-label="Facebook" className="ftrs-socialicon ftrs-socialicon--facebook ml-3" href="https://www.facebook.com/futurerockstars.cz" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        <a aria-label="Instagram" className="ftrs-socialicon ftrs-socialicon--instagram ml-3" href="https://www.instagram.com/future.rockstars" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a aria-label="Strava" className="ftrs-socialicon ftrs-socialicon--strava ml-3" href="https://www.strava.com/clubs/future-rockstars" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faStrava} />
                        </a>
                    </div>

                    <p className="mt-5 ftrs-contact__info text-basic">
                        IČO: 08689458<br />
                        DIČ: CZ08689458<br />
                        Společnost zapsána v&nbsp;obchodním rejstříku vedeném Městským soudem v&nbsp;Praze, oddíl&nbsp;C, vložka&nbsp;323312
                    </p>
                </Col>

            </Row>

        </Container>

    </Layout>

</div>

)

export default Contact